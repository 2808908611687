import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import NoPostsFeed from '../../../common/containers/no-posts-feed';
import PostListProGallery from '../../../common/components/post-list-pro-gallery';
import { SECTION_POST_LIST } from '@wix/communities-blog-client-common/dist/src/constants/wix-params';
import {
  POST_LIST_WIDGET_DEFAULTS,
  POST_LIST_SETTINGS_PARAMS,
  POST_LIST_LAYOUT_PARAMS,
} from '@wix/communities-blog-client-common/dist/src/constants/post-list-widget-constants';
import { getFeedPosts } from '../../../common/selectors/post-selectors';
import { getIsEntityLoading } from '../../../common/store/is-loading/is-loading-selectors';
import { getAppSettingsNumber, getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import { getLayoutType } from '../../../common/selectors/layout-selectors';

const { sliderArrowsPosition } = POST_LIST_LAYOUT_PARAMS;

class PostList extends Component {
  componentDidUpdate({ pageSize, featuredOnly }) {
    if (this.props.pageSize !== pageSize || this.props.featuredOnly !== featuredOnly) {
      this.props.fetchPostListPosts();
    }
  }

  render() {
    const { posts, isLoading, componentId, layoutType, layoutOptions } = this.props;
    const hasPosts = Boolean(posts.length);

    if (!isLoading && !hasPosts) {
      return <NoPostsFeed />;
    }

    return (
      <PostListProGallery
        className={layoutOptions.arrowsPosition === sliderArrowsPosition.values.ON_GALLERY ? 'arrows-on-gallery' : ''}
        layoutName={POST_LIST_WIDGET_DEFAULTS.layoutName}
        layoutType={layoutType}
        entityCount={POST_LIST_WIDGET_DEFAULTS.entityCount}
        layoutDefaults={POST_LIST_WIDGET_DEFAULTS}
        domId={componentId}
        allPosts={posts}
        currentPagePosts={posts}
        isLoading={isLoading}
        showCreatePostAction={false}
        section={SECTION_POST_LIST}
        hideThreeDots={true}
        hideAuthorBadge={true}
        layoutOptions={layoutOptions}
      />
    );
  }
}

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const {
    sliderShowArrows,
    sliderArrowsSize,
    sliderAutoSlide,
    sliderPauseTime,
    sliderArrowsPosition,
    sliderArrowsColor,
    sliderLoop,
  } = POST_LIST_LAYOUT_PARAMS;
  return {
    posts: getFeedPosts(state),
    layoutType: getLayoutType(state, SECTION_POST_LIST) || POST_LIST_WIDGET_DEFAULTS.layoutType,
    isLoading: getIsEntityLoading(state, 'postListPosts'),
    componentId: host.id,
    pageSize: getAppSettingsNumber(
      state,
      POST_LIST_SETTINGS_PARAMS.entityCount.wixParam,
      POST_LIST_SETTINGS_PARAMS.entityCount.default,
    ),
    layoutOptions: {
      showArrows: getAppSettingsValue({
        state,
        key: sliderShowArrows.appSettingsPath,
        fallback: sliderShowArrows.defaultValue,
      }),
      arrowsSize: getAppSettingsValue({
        state,
        key: sliderArrowsSize.appSettingsPath,
        fallback: sliderArrowsSize.defaultValue,
      }),
      autoSlide: getAppSettingsValue({
        state,
        key: sliderAutoSlide.appSettingsPath,
        fallback: sliderAutoSlide.defaultValue,
      }),
      pauseTime: getAppSettingsValue({
        state,
        key: sliderPauseTime.appSettingsPath,
        fallback: sliderPauseTime.defaultValue,
      }),
      arrowsPosition: getAppSettingsValue({
        state,
        key: sliderArrowsPosition.appSettingsPath,
        fallback: sliderArrowsPosition.defaultValue,
      }),
      arrowsColor: getAppSettingsValue({
        state,
        key: sliderArrowsColor.appSettingsPath,
        fallback: sliderArrowsColor.defaultValue,
      }),
      loop: getAppSettingsValue({ state, key: sliderLoop.appSettingsPath, fallback: sliderLoop.defaultValue }),
    },
    featuredOnly: getAppSettingsValue({ state, key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath }),
    fetchPostListPosts: actions.fetchPostListPostsPromisified,
  };
};

export default connect(mapRuntimeToProps)(PostList);
